<template>
  <div class="OneBuyBox"  v-wechat-title="this.productName+ '-药百万'">
    <div class="OneBuyDetailBox">
        <div class="ProductA_Box">
          <div class="ProductA_Main" v-if="productAList!=''">
              <div class="ProductA_left">
                 <img :src="productAList[0].pic?productAList[0].pic + picSuffix : defaultImage" alt="图片"/>
                 <div class="TodayGoodIcon"><img src="@/assets/TodayGood.png" alt=""></div>
              </div>
              <div  class="ProductA_right">
                  <el-popover
                    placement="bottom-end"
                    width="200"
                    trigger="hover"
                    >
                    <div>{{goodsinfo.description}}</div>
                    <div class="ruleBtn" slot="reference">活动规则 <i class="el-icon-question"></i></div>
                  </el-popover>
                  <h3 class="shopName" :title="productAList[0].name">{{ productAList[0].name | defaultName }}</h3>
                  <div class="ProductA_Con">
                      <p>采购数量：{{productAList[0].minOrderNum}}{{productAList[0].unit}} <span>|</span> 药品规格：{{productAList[0].specification}}</p>
                      <p>效期优于：{{productAList[0].validDateFromat}}</p>
                  </div>
                  <p class="price">{{productAList[0].price  | capitalize}}</p>
                  <div class="btnBox">
                    <div  v-if="goodsinfo.minSku>0" :class="checkedNums >= goodsinfo.minSku?'BuyBtn active':'BuyBtn'"    @click="BuyCartFun(1)">
                      <p>立即购买</p>
                      <p v-show="checkedNums < goodsinfo.minSku">需购买{{goodsinfo.minSku}}个品种可享受活动价</p>
                    </div>

                    <div  v-else :class="Number(totalPrice) >= Number(goodsinfo.minPrice)?'BuyBtn active':'BuyBtn'"    @click="BuyCartFun(2)">
                      <p>立即购买</p>
                      <p v-show="totalPrice < Number( goodsinfo.minPrice)">需购买{{goodsinfo.minPrice}}元可享受活动价</p>
                    </div>
                    
                    <div v-show="productAList[0].price !=='***'" :class="checkedBtnFlag?'checkedBtn active':'checkedBtn'" @click="OneBuyCartListFun()">
                      已选商品 (<span :class="checkedNums>0?'active':''">{{checkedNums}}</span>) <i class="el-icon-arrow-down"></i>
                    </div>
                    <div v-show="checkedBtnFlag" class="OneBuyCartListBox">
                      <el-row :gutter="20" class="OneBuyItemBox">
                        <el-col :span="8" v-for="(item,index) in OneBuyCartList" :key="index">
                          <div class="ItemLeft">
                              <img :src="item.product.pic?item.product.pic + picSuffix : defaultImage" alt="图片"/>
                          </div>
                          <div class="ItemRight">
                              <p>{{item.product.name}}</p>
                              <p>数量：{{item.quantity}}{{item.product.unit}}</p>
                              <div @click="DelFun(item)">
                                <span>{{item.product.price}}   <i class="el-icon-delete"></i></span>
                              </div>
                          </div>
                        </el-col>
                      </el-row>
                    </div>

                  </div>
              </div>
          </div>
        </div>
        <div class="ProductB_Tit">
           <div >
             <img src="@/assets/ProductB_TitBg.png" alt="">
           </div>
           <p  v-if="goodsinfo.minSku>0" >需购买{{goodsinfo.minSku}}个品种</p>
           <p  v-else>需购买{{goodsinfo.minPrice}}元<span>(当前<b>{{totalPrice}}</b>元)</span>可享受活动价 </p>
        </div>
        <div class="ProductB_Con" v-if="productBList!=''" >
           <div class="BproductBox">
                <OneBuyItem v-for="(item,index) in productBList" :key="index"  :info="item" :index="index"  @changeNums="changeCheckedNums"></OneBuyItem>
                <div class="clear"></div>
           </div>
        </div>
    </div>
   

  </div>
</template>

<script>
import {
  Message,
  MessageBox,
} from "element-ui";
const OneBuyItem = () => import("components/product/OneBuyItem.vue");
import {oneBuyDetail} from  "api/activity.js";
import {OneBuyCount,CartList, removeCart} from "api/product.js";
export default {
  name: "OneBuyDetail",
  data() {
    return {
         totalPrice:0,
      checkedBtnFlag:false,//初始已选商品按钮  状态
       picSuffix: localStorage.getItem("productPic"),
      productName:'',
      goodsinfo: "",
      productAList:[],
      productBList:[],
      checkedNums:0, //选中商品数量
      OneBuyCartList:[], //一元购购物车商品列表
      promotionId:'',
    };
  },
  components: {
    OneBuyItem
  },
  computed: {},
  created() {
    // 获取一元购商品详情
     this.getOneBuyDetail()
     // 获取一元购购物车品种数
    this.changeCheckedNums()
  },
  methods: {
    // 关闭   购物车列表
closeFun(){
  this.checkedBtnFlag=false
},

// 立即购买  BuyCartFun
BuyCartFun(type){

  if(type==1){
    if(this.checkedNums < this.goodsinfo.minSku){
      return false
    }
  }else{
    if(Number(this.totalPrice)  < Number( this.goodsinfo.minPrice)){
      return false
    }
  }
     let datailsArr=[];
      this.OneBuyCartList.forEach(item => {
        //  console.log(Number(item.minOrderNum)*Number(item.price))
            datailsArr.push({
              goodsCount:item.quantity,
              goodsId:item.product.id,
              goodNum:item.goodNum,
              isRecentExpiration:0,
              promotionId:item.promotionId
            })
            this.promotionId=item.promotionId
      });
      // this.OneBuyCartList.id
      let datas={ 
        details: datailsArr,
        orderType:10,
        promotionId:this.promotionId
      }
      sessionStorage.setItem('comBo',JSON.stringify(datas))
      // 单独成单，跳订单结算,query:{ids:moreIds}}
      this.$router.push({ name: "GoodsAmount",query:{id:"combo"}});
},

    //删除单个商品
    DelFun(item) {
      MessageBox.confirm("您确定要删除该商品吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let ids = {
            ids: item.id
          };
          // (ids);
          // 删除单个商品接口
          removeCart(ids).then((data) => {
            if (data.data.code == 200) {
              Message({
                type: "success",
                message: "删除成功!",
              });
              this.changeCheckedNums()
            } else {
              Message({
                type: "warning",
                message: "删除失败!",
              });
            }
          });
        })
        .catch(() => {
          Message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取一元购购物车品种数
    changeCheckedNums(){
      OneBuyCount().then(res=>{
        this.checkedNums=res.data
        if(this.checkedNums==0){
          this.checkedBtnFlag=false
          this.totalPrice=0
        }else{
            let datas = {
              size: 2000,
              cartType:4,
            }
            CartList(datas).then(
              res => {
                    this.OneBuyCartList=res.data.records
                    this.totalPrice=0;
                    this.OneBuyCartList.forEach(item=>{
                        this.totalPrice+=Number(Number(item.quantity)*Number(item.product.price))
                    })
                    this.totalPrice=this.totalPrice.toFixed(2)
              })
        }
      })
      
      
    },
// 获取一元购购物车商品列表
    OneBuyCartListFun(){
      if(Number(this.checkedNums)==0){
        return false
      }
      this.checkedBtnFlag=!this.checkedBtnFlag
      let datas = {
        size: 2000,
        cartType:4
      }
      CartList(datas).then(
        res => {
            console.log('获取一元购购物车商品列表',res.data.records)
              this.OneBuyCartList=res.data.records
        })
    },
        //  首页根据标签获取列表页
        getOneBuyDetail(){
            // 显示loading
             const parmes = this.$route.query;
            this.$loading({ fullscreen: true ,background:'#ffffff45'})
            oneBuyDetail(parmes).then((res)=>{
                if(res.data.code==400){
                  this.$message.error(res.data.msg);
                  let that=this
                  setTimeout(function(){that.$router.push({name:"Home"})},500);
                }
                this.productName=res.data.data.name
                this.goodsinfo = res.data.data;
                this.productAList=this.goodsinfo.productAList
                this.productBList=this.goodsinfo.productBList
                 // 关闭loading
                    this.$loading().close();


               
            })
                
        },

   
  },
   watch: {
    
  },


  mounted() {},
  updated() {},
};
</script>

<style lang="less" scoped>
@import "~style/index.less";

/* 隐藏未编译的变量 */
[v-cloak] {
  display: none;
}
.OneBuyDetailBox{
      background: url('../assets/OneBuyBg.png') no-repeat;
      background-size: 100%;
      .ProductA_Box{
          width: 1200px;
          margin: 0 auto;
          padding:370px 0 35px 0;
          .ProductA_Main{
            width: 100%;
            background: #fff;
            width: calc(100% - 70px );
            height: 400px;
            padding: 35px;
           
            .ProductA_left{
              width: 398px;
              height:398px;
              border:1px solid #ccc;
              position: relative;
              float: left;
              .TodayGoodIcon{
                  width: 100px;
                  height: 100px;
                  position: absolute;
                  left: -1px;
                  top:0;
                  img{
                     width: 100%;
                      height: 100%;
                  }
              }
              img{
                width: 100%;
                height: 100%;
              }
            }
            .ProductA_right{
                // width: calc(100% - 440px);
                width: calc(100% - 460px);
                height: 100%;
                float: left;
                padding-left: 40px;
                 position: relative;
                .ruleBtn{
                  position: absolute;
                  right:0;
                  top: 0;
                  line-height:25px;
                  font-size: 14px;
                  color: #999;
                  &:hover{
                    color:#f43c38 ;
                  }
                }
                h3{
                  padding:20px 0 20px 0;
                  line-height:30px;
                  font-size: 26px;
                  font-weight: bold;
                }
                .ProductA_Con{
                  background:#FFF8F3;
                  width:560px;
                  height:80px;
                  padding:25px 10px;
                  p{
                    font-size: 20px;
                    color: #666;
                    line-height:30px;
                    span{
                      padding:0 20px;
                    }
                  }
                  p:nth-child(2){
                    margin-top:20px;
                  }
                }
                .price{
                  color: #F43C38 ;
                  font-size: 30px;
                  line-height:100px;
                }
                .btnBox{
                   position: relative;
                      .OneBuyCartListBox{
                        position: absolute;
                        right:0;
                        top:76px;
                        height:auto;
                        border:1px solid #ccc;
                        padding:5px ;
                        z-index:1;
                        width: 732px;
                        background: #fff;
                       
                        .OneBuyItemBox{
                          margin:0!important;
                           .el-col{
                            padding: 0!important;
                          }
                          .ItemLeft{
                            float: left;
                            img{
                              width:82px;
                              height:82px;
                              border: 1px solid #E0E0E0;
                              margin: 15px;
                            }
                          }
                          .ItemRight{
                            float: left;
                              padding: 15px 0;
                              width:128px;
                              p:nth-child(1){
                                font-size: 14px;
                                color: #666666;
                                line-height: 19px;
                                letter-spacing: 1px;
                              }
                               p:nth-child(2){
                                 margin-top: 10px;
                                font-size:12px;
                                color: #666666;
                                line-height: 19px;
                                letter-spacing: 1px;
                              }
                              div{
                                padding-top:17px ;
                                line-height: 16px;
                                
                                span{
                                  font-size: 12px;
                                  font-weight: bold;
                                  color: #F43C38;
                                }
                                i{
                                  padding-right:30px ;
                                  float: right;
                                  font-size:13px;
                                  font-weight: bold;
                                  color: #e0e0e0;
                                  padding-top: 4px;
                                  &:hover{
                                    color:#F43C38
                                  }
                                }
                              }
                          }
                          
                        }
                      }
                  .BuyBtn{
                      float: left;
                      height: 40px;
                      border-radius:30px ;
                      width: 210px;
                      padding:10px 0;
                      border:1px solid #E0E0E0 ;
                      text-align: center;
                      background:#F7F7F7  ;
                      &.active{
                        background: #F43C38 ;
                        border:1px solid #F43C38  ;
                         p:nth-child(1){
                            line-height:36px;
                            color: #fff;
                            font-size:30px;
                            font-weight: bold;
                          }
                      }
                      p:nth-child(1){
                        line-height: 24px;
                        color: #666666;
                         font-size:20px;
                      }
                       p:nth-child(2){
                         color: #666666;
                         font-size: 12px;
                        line-height:16px;
                        
                      }
                  }
                  .checkedBtn{
                      float: left;
                      font-size: 20px;
                      color: #666666;
                      padding:0 10px;
                      line-height: 60px;
                       position: absolute;
                       left:242px;
                         top:0px;
                      z-index: 2;
                      &.active{
                        padding-bottom:15px;
                        border:1px solid #ccc;
                        border-bottom-color:#fff;
                      }
                      span.active{
                         color: #F43C38;
                      }
                      i{
                        // font-weight: bold;
                      }
                  }
                }
            }
          }
      }
      .ProductB_Tit{
        background: #f7f7f7;
        div{
          img{
            width: 211px;
            height: 42px;
            margin:0 auto;
            display: block;
          }
        }
        p{
          text-align: center;
          font-size: 20px;
          color: #333333;
          font-family: MicrosoftYaHei;
          line-height: 26px;
          letter-spacing: 1px;
          span{
            color: #333333;
          font-weight: normal;
          font-size:14px;
            b{
              font-weight: bold;
              color: #f43c38;
            }
          }
        }
      }
}

 .ProductB_Con{
    height: auto;
    margin-bottom:20px;
      background:#f7f7f7; ;

    .BproductBox{
      width: 1200px;
      margin:0 auto;
      .item:nth-child(5n + 0){
        margin-right: 0px;
      }
    }
   
  }



.clear{
  clear: both;
}

</style>
